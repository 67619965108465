var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.infoshow)?_c('div',[_c('div',{staticClass:"item_line"},[_c('span',{staticClass:"titlename",staticStyle:{"font-size":"18px","color":"#333","font-weight":"bold"}},[_vm._v(_vm._s(_vm.form.name))])]),_c('div',{staticClass:"item_line"},[_c('span',{staticClass:"titlename"},[_vm._v(_vm._s(_vm.form.wymeetingtype_name))])]),_c('div',{staticClass:"item_line"},[_vm._m(0),_c('span',{staticClass:"valuename"},[_vm._v(_vm._s(_vm.form.addtime))])]),_c('div',{staticClass:"item_line"},[_vm._m(1),_c('span',{staticClass:"valuename"},[_vm._v(_vm._s(_vm.form.kstime)+" 至 "+_vm._s(_vm.form.endtime))])]),_c('div',{staticClass:"item_line"},[_vm._m(2),_c('span',{staticClass:"valuename"},[_vm._v(_vm._s(_vm.form.qdtime)+" 至 "+_vm._s(_vm.form.jstime))])]),_c('div',{staticClass:"item_line"},[_vm._m(3),_c('span',{staticClass:"valuename"},[_vm._v(_vm._s(_vm.form.address))])]),_c('div',{staticClass:"item_line",staticStyle:{"align-items":"flex-start"}},[_vm._m(4),_c('div',{staticStyle:{"width":"80%"}},[_c('span',{staticClass:"valuename"},[_vm._v(_vm._s(_vm.form.chuser_name)+";")])])]),_c('div',{staticClass:"item_line",staticStyle:{"align-items":"flex-start"}},[_vm._m(5),_c('div',{staticStyle:{"width":"80%"},domProps:{"innerHTML":_vm._s(_vm.form.content)}})])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" 会议时间：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" 报名时间：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-time"}),_vm._v(" 签到时间：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-location-information"}),_vm._v(" 会议地点：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-user"}),_vm._v(" 参会人员：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titlename"},[_c('i',{staticClass:"el-icon-document"}),_vm._v(" 会议详情：")])
}]

export { render, staticRenderFns }